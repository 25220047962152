<template>
  <div class="app-box">
    <van-form @submit="onSubmit">
      <!-- <div class="blockBox blockBox1">
      <div class="titleBox">签约城市地址</div>
      <div class="inputLi">
        <van-field
          :value="address.province + address.city"
          label="省市"
          input-align="right"
          readonly
        />
        <van-field
          :value="address.area"
          label="区/县"
          input-align="right"
          readonly
        />
      </div>
    </div> -->
      <!-- end -->
      <div class="blockBox">
        <div class="titleBox">签约个人信息</div>
        <div class="inputLi">
          <van-field
            v-model="fortData.name"
            label="姓名"
            input-align="right"
            placeholder="姓名"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="fortData.id_card"
            label="身份证"
            input-align="right"
            placeholder="身份证"
            :rules="[{ validator, required: true,message:'请输入正确的身份证号' }]"
          />
          <van-field
            v-model="fortData.phone"
            label="联系电话"
            input-align="right"
            placeholder="联系电话"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="fortData.code"
            maxlength="6"
            label="验证码"
            input-align="right"
            :rules="[{ required: true }]"
          >
            <template #button>
              <!-- <span class="ocndtext">获取验证码</span> -->
              <van-button
                type="default"
                class="ocndtext"
                size="mini"
                :disabled="isSmsSend"
                @click="sendSmsCode"
                >{{ sendBtnText }}</van-button
              >
            </template>
          </van-field>
          <!-- end实名认证 -->
          <van-field
            value=""
            label="实名认证"
            type="tel"
            input-align="right"
            readonly
          />
        </div>
        <!-- edn -->
        <p class="textColor">身份证人像面照片</p>
        <!-- end照片上传 -->
        <div class="cardId">
          <img
            class="imgId"
            v-if="fortData.idcard_front"
            :src="fortData.idcard_front"
            alt=""
            @click="imgClick(fortData.idcard_front)"
          />
          <img class="imgId" v-else src="../../assets/img/just.jpg" alt="" />
          <van-uploader
            class="imgBoelf"
            :max-count="1"
            :after-read="afterRead"
          />
          <span class="uploadImg"
            ><i class="iconfont icon-paizhao"></i>点击上传</span
          >
        </div>
        <p class="textColor textColor1">身份证国徽面照片</p>
        <div class="cardId">
          <img
            class="imgId"
            v-if="fortData.idcard_back"
            :src="fortData.idcard_back"
            alt=""
            @click="imgClick(fortData.idcard_back)"
          />
          <img class="imgId" v-else src="../../assets/img/back.jpg" alt="" />
          <van-uploader
            class="imgBoelf"
            :max-count="1"
            :after-read="afterRead1"
          />
          <span class="uploadImg"
            ><i class="iconfont icon-paizhao"></i>点击上传</span
          >
        </div>
        <p class="bottomrWarning">
          <van-icon class="iconmIE" name="warning" />请确认后提交!
        </p>
      </div>
      <!-- end -->
      <div class="blockBox blockBox2">
        <div class="inputLi">
          <van-field
            value="*必填"
            label="手写签名"
            input-align="right"
            right-icon="arrow"
            readonly
            @click="show = true"
          />
          <img class="resuImg" v-if="resultImg" :src="resultImg" alt="" />
        </div>
        <van-button class="maoeorf" type="info" block native-type="submit"
          >下一步</van-button
        >
      </div>
    </van-form>
    <!-- 签名 -->
    <van-popup v-model="show" position="bottom">
      <p class="titleBoe">请写正楷签名</p>
      <vue-esign
        ref="esign"
        :width="800"
        :height="500"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        bgColor="#EDF2F5FF"
      />
      <div class="fleoUl">
        <van-button class="liFlex liFlex1" type="warning" @click="handleReset"
          >清空画板</van-button
        >
        <van-button class="liFlex" type="primary" @click="handleGenerate"
          >提交</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getItem, setItem, removeItem } from "@/utils/storage";
import { uploadImage, sendSms, settleInOper } from "@/request/api";
import { ImagePreview, Toast } from "vant";

export default {
  data() {
    return {
      address: [], //城市信息
      fortData: {
        name: "",
        phone: "",
        idcard_front: "",
        idcard_back: "",
        code: "",
        sign_pic_url: "",
        id_card: "",
      },
      isSmsSend: false,
      sendBtnText: "获取验证码",
      timer: null,
      counter: 60,
      loadNum: "60",
      show: false,
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "#EDF2F5FF",
      resultImg: "",
      isCrop: false,
    };
  },
  mounted() {
    this.address = getItem("address");
  },
  methods: {
    validator(val) {
      return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
        val
      );
    },
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((resu) => {
          this.show = false;
          let data = {
            image: resu,
          };
          uploadImage(data).then((res) => {
            this.fortData.sign_pic_url = res.data;
            this.resultImg = resu;
          });
        })
        .catch((err) => {
          Toast.fail("签名不能为空");
        });
    },
    afterRead(file) {
      Toast.loading({
        message: "上传中...",
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      const pngIs = file.file.type === "image/jpeg";
      const jpegIs = file.file.type === "image/png";
      const isLt2M = file.file.size / 1024 / 1024 < 3;
      if (!pngIs && !jpegIs) {
        Toast("上传文件只能是 jpg、png格式!");
        return;
      }
      if (!isLt2M) {
        Toast("只能上传小于3M的图片");
        return;
      }
      let data = {
        image: file.content,
      };
      uploadImage(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          Toast.clear();
          return;
        }
        this.fortData.idcard_front = res.data;
        Toast.clear();
      });
    },
    afterRead1(file) {
      Toast.loading({
        message: "上传中...",
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      const pngIs = file.file.type === "image/jpeg";
      const jpegIs = file.file.type === "image/png";
      const isLt2M = file.file.size / 1024 / 1024 < 3;
      if (!pngIs && !jpegIs) {
        Toast("上传文件只能是 jpg、png格式!");
        return;
      }
      if (!isLt2M) {
        Toast("只能上传小于3M的图片");
        return;
      }
      let data = {
        image: file.content,
      };
      uploadImage(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          Toast.clear();
          return;
        }
        this.fortData.idcard_back = res.data;
        Toast.clear();
      });
    },
    imgClick(img) {
      ImagePreview([img]);
    },
    sendSmsCode() {
      const reg = /^1\d{10}$/;
      console.log(this.fortData.phone);
      if (!reg.test(this.fortData.phone)) {
        Toast("请输入正确的手机号码");
        return;
      }
      //   发送验证码
      let data = {
        mobile: this.fortData.phone,
      };
      sendSms(data).then((res) => {
        this.isSmsSend = true;
        this.countDown();
        Toast(res.message);
      });
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter}秒后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    onSubmit() {
      if (
        this.fortData.idcard_back == "" ||
        this.fortData.idcard_front == "" ||
        this.fortData.sign_pic_url == ""
      ) {
        Toast.fail("请检查身份证,签名是否填写完毕");
        return;
      }
      let data = {
        truename: this.fortData.name,
        phone: this.fortData.phone,
        idcard_front: this.fortData.idcard_front,
        idcard_back: this.fortData.idcard_back,
        code: this.fortData.code,
        sign_pic_url: this.fortData.sign_pic_url,
        id_card: this.fortData.id_card,
        // province_code: this.address.provinceId, //省
        // city_code: this.address.cityId, //市
        // area_code: this.address.areaId, //区
      };
      settleInOper(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          return;
        }
        this.$router.push({ path: "/ToPayOper", query: { id: res.data.id } });
      });
    },
  },
};
</script>

<style scoped>
.blockBox {
  padding: 0.3rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 7px solid #edf2f5ff;
}
.titleBox {
  position: relative;
  padding-left: 0.2rem;
  font-size: 0.43rem;
  font-weight: bold;
}
.titleBox::before {
  content: "";
  display: inline-block;
  width: 4px;
  border-radius: 10px;
  height: 15px;
  background: #0e8affff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.inputLi ::v-deep.van-field {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f5ff;
}
.inputLi ::v-deep.van-cell::after {
  content: "";
  border: none;
}
.inputLi ::v-deep.van-field:nth-last-child(1) {
  border: none;
}
.ocndtext {
  color: #0e8affff;
  border: none;
  vertical-align: bottom;
  font-size: 0.35rem;
}
.textColor {
  color: #999dadff;
  padding-bottom: 0.3rem;
  font-size: 0.35rem;
}
.cardId {
  border: 1px dashed #d6d6d6ff;
  border-radius: 10px;
  margin: 10px 0;
  text-align: center;
  padding-bottom: 20px;
  position: relative;
}
.cardId img {
  width: 80%;
  height: 80%;
  margin: auto;
  padding: 0.5rem;
}
.uploadImg {
  font-size: 0.4rem;
  color: #999dadff;
}
.icon-paizhao {
  font-size: 0.6rem;
  vertical-align: middle;
  margin-right: 5px;
}
.textColor1 {
  padding: 0;
}
.bottomrWarning {
  color: #ef1a1aff;
  font-size: 0.35rem;
}
.iconmIE {
  vertical-align: middle;
  margin-right: 3px;
  font-size: 0.4rem;
}
.blockBox1 {
  padding-bottom: 0;
}
.tonead {
  font-size: 0.4rem;
}
.blockBox2 {
  padding: 0rem 0.3rem;
}
.imgBoelf {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
.cardId .imgId {
  max-height: 4rem;
}
.maoeorf {
  margin-bottom: 0.4rem;
}
.resuImg {
  width: 100%;
  height: 5rem;
  padding: 0.5rem 0;
  border-radius: 10px;
}
.fleoUl {
  display: flex;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
}
.fleoUl .liFlex {
  flex: 1;
}
.liFlex1 {
  margin-right: 0.3rem;
}
.titleBoe {
  text-align: center;
  font-size: 0.45rem;
  background: #edf2f5ff;
  padding: 0.2rem 0;
}
</style>